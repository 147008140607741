<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="80px">
    <el-form-item label="项目名称" prop="name">
      <el-input v-model="dataForm.name" placeholder="项目名称"></el-input>
    </el-form-item>
    <el-form-item label="地址信息" prop="address">
      <el-input v-model="dataForm.address" placeholder="地址信息"></el-input>
    </el-form-item>
    <el-form-item label="产量信息" prop="yield">
      <el-input v-model="dataForm.yield" placeholder="产量信息"></el-input>
    </el-form-item>
    <el-form-item label="煤质信息" prop="coalquality">
      <el-input v-model="dataForm.coalquality" placeholder="煤质信息"></el-input>
    </el-form-item>
    <el-form-item label="开采方式" prop="miningmethod">
      <el-input v-model="dataForm.miningmethod" placeholder="开采方式"></el-input>
    </el-form-item>
    <el-form-item label="灾害类型" prop="disastertype">
      <el-input v-model="dataForm.disastertype" placeholder="灾害类型"></el-input>
    </el-form-item>
    <el-form-item label="服务年限" prop="lengthofservice">
      <el-input v-model="dataForm.lengthofservice" placeholder="服务年限"></el-input>
    </el-form-item>
    <el-form-item label="接入时间" prop="createdate">
      <el-input v-model="dataForm.createdate" placeholder="接入时间"></el-input>
    </el-form-item>
    <el-form-item label="操作用户" prop="uid">
      <el-input v-model="dataForm.uid" placeholder="操作用户"></el-input>
    </el-form-item>
    <el-form-item label="是否删除" prop="isdelete">
      <el-input v-model="dataForm.isdelete" placeholder="是否删除"></el-input>
    </el-form-item>
    <el-form-item label="" prop="remark">
      <el-input v-model="dataForm.remark" placeholder=""></el-input>
    </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  export default {
    data () {
      return {
        visible: false,
        dataForm: {
          id: 0,
          name: '',
          address: '',
          yield: '',
          coalquality: '',
          miningmethod: '',
          disastertype: '',
          lengthofservice: '',
          createdate: '',
          uid: '',
          isdelete: '',
          remark: ''
        },
        dataRule: {
          name: [
            { required: true, message: '项目名称不能为空', trigger: 'blur' }
          ],
          address: [
            { required: true, message: '地址信息不能为空', trigger: 'blur' }
          ],
          yield: [
            { required: true, message: '产量信息不能为空', trigger: 'blur' }
          ],
          coalquality: [
            { required: true, message: '煤质信息不能为空', trigger: 'blur' }
          ],
          miningmethod: [
            { required: true, message: '开采方式不能为空', trigger: 'blur' }
          ],
          disastertype: [
            { required: true, message: '灾害类型不能为空', trigger: 'blur' }
          ],
          lengthofservice: [
            { required: true, message: '服务年限不能为空', trigger: 'blur' }
          ],
          createdate: [
            { required: true, message: '接入时间不能为空', trigger: 'blur' }
          ],
          uid: [
            { required: true, message: '操作用户不能为空', trigger: 'blur' }
          ],
          isdelete: [
            { required: true, message: '是否删除不能为空', trigger: 'blur' }
          ],
          remark: [
            { required: true, message: '不能为空', trigger: 'blur' }
          ]
        }
      }
    },
    methods: {
      init (id) {
        this.dataForm.id = id || 0
        this.visible = true
        this.$nextTick(() => {
          this.$refs['dataForm'].resetFields()
          if (this.dataForm.id) {
            this.$http({
              url: this.$http.adornUrl(`/coal/coal/info/${this.dataForm.id}`),
              method: 'get',
              params: this.$http.adornParams()
            }).then(({data}) => {
              if (data && data.code === 0) {
                this.dataForm.name = data.coal.name
                this.dataForm.address = data.coal.address
                this.dataForm.yield = data.coal.yield
                this.dataForm.coalquality = data.coal.coalquality
                this.dataForm.miningmethod = data.coal.miningmethod
                this.dataForm.disastertype = data.coal.disastertype
                this.dataForm.lengthofservice = data.coal.lengthofservice
                this.dataForm.createdate = data.coal.createdate
                this.dataForm.uid = data.coal.uid
                this.dataForm.isdelete = data.coal.isdelete
                this.dataForm.remark = data.coal.remark
              }
            })
          }
        })
      },
      // 表单提交
      dataFormSubmit () {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
            this.$http({
              url: this.$http.adornUrl(`/coal/coal/${!this.dataForm.id ? 'save' : 'update'}`),
              method: 'post',
              data: this.$http.adornData({
                'id': this.dataForm.id || undefined,
                'name': this.dataForm.name,
                'address': this.dataForm.address,
                'yield': this.dataForm.yield,
                'coalquality': this.dataForm.coalquality,
                'miningmethod': this.dataForm.miningmethod,
                'disastertype': this.dataForm.disastertype,
                'lengthofservice': this.dataForm.lengthofservice,
                'createdate': this.dataForm.createdate,
                'uid': this.dataForm.uid,
                'isdelete': this.dataForm.isdelete,
                'remark': this.dataForm.remark
              })
            }).then(({data}) => {
              if (data && data.code === 0) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 1500,
                  onClose: () => {
                    this.visible = false
                    this.$emit('refreshDataList')
                  }
                })
              } else {
                this.$message.error(data.msg)
              }
            })
          }
        })
      }
    }
  }
</script>
